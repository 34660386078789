import React, { FC, useEffect, useRef } from 'react';
import Header from './Header';
import Button from './Button';
import Form from './Form';
import Footer from './Footer';
import Accordion from './Accordion';

import { FormattedMessage } from 'react-intl';
import '../styles/App.scss';
import translate from '../i18n/translate';

import abstraction from '../assets/icons/symbol.svg';
import research from '../assets/images/research.png';
import contract from '../assets/images/contract.png';
import database_1 from '../assets/images/database_1.png';
import hosting from '../assets/images/hosting.png';
import launch from '../assets/images/launch.png';
import hotline from '../assets/images/hotline.png';
import scalability from '../assets/images/scalability.png';
import interface_1 from '../assets/images/interface.png';
import rocket from '../assets/images/rocket-launch.png';
import time from '../assets/images/time-sheet.png';
import payment from '../assets/images/secure-payment.png';
import analytics from '../assets/images/analytics.png';

import usersGuide from '../assets/documents/users_guide.pdf';
import installationInstructions from '../assets/documents/installation_instructions.pdf';
import softwareAdditionalInteraction from '../assets/documents/software_additional_interaction.pdf';
import lifeCycleProcessesDescription from '../assets/documents/life_cycle_processes_description.pdf';

import { LOCALES } from '../i18n';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface ILocaleProps {
    name: string;
    locale: string;
}

interface FrontPageProps {
    currentLocale: string;
    languageChange: (lang: string) => void;
}

const FrontPage: FC<FrontPageProps> = ({ currentLocale, languageChange }) => {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!['/ru', '/en'].includes(location.pathname)) {
            navigate('/' + currentLocale);
            return;
        }

        if (params.lang && currentLocale !== params.lang) {
            languageChange(params.lang);
        }
    });

    const languages: ILocaleProps[] = [
        { name: 'Русский', locale: LOCALES.RUSSIAN },
        { name: 'English', locale: LOCALES.ENGLISH }
    ];

    const myRef = useRef<HTMLDivElement>(null);
    const scrollToRef = (ref: any) => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    };

    const executeScroll = () => {
        scrollToRef(myRef);
    };

    return (
        <div>
            <section className='main_sec'>
                <div className='switcher'>
                    <select value={currentLocale} onChange={(locale) => {
                        languageChange(locale.target.value);
                        navigate('/' + locale.target.value);
                    }}>
                        {languages.map(({ name, locale }) => (
                            <option key={locale} value={locale}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='main_sec-wrapper'>
                    <Header/>
                    <div className='container'>
                        <div className='cover_outside'>
                            <div className='left'>
                                <h1><FormattedMessage id='headerTitle'/></h1>
                                <p className='name'>AxiLink</p>
                                <span><FormattedMessage id='headerSubtitle'/></span>
                                <Button onClick={executeScroll}>{translate('getDemo')}</Button>
                            </div>
                            <div className='right'>
                                <img src={abstraction} alt='abstraction'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='how'>
                <div className='container'>
                    <h2><FormattedMessage id='how'/></h2>
                    <div className='cover_outside'>
                        <div className='step_block'>
                            <div className='img_block'><img src={research} alt='ico_1'/></div>
                            <div className='info_block _half-width'>{translate('strongProductSides')}</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={contract} alt='ico_2'/></div>
                            <div className='info_block _half-width'>{translate('formatsAgree')}</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={database_1} alt='ico_3'/></div>
                            <div className='info_block'>{translate('implementSystem')}</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={hosting} alt='ico_4'/></div>
                            <div className='info_block'>{translate('connectSources')}</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={launch} alt='ico_5'/></div>
                            <div className='info_block'>{translate('startProject')}</div>
                        </div>
                        <div className='step_block'>
                            <div className='img_block'><img src={hotline} alt='ico_6'/></div>
                            <div className='info_block'>{translate('arrangeSupport')}</div>
                        </div>
                    </div>
                    <p><b>{translate('firstStep')}</b>{translate('takingObligations')}</p>
                </div>
            </section>

            <section className='what_is'>
                <div className='container'>
                    <h2>{translate('whatIs')}<b>{translate('axiLink')}{translate('questionMark')}</b></h2>
                    <p>{translate('axiLinkDescMain')}</p>
                    <p className='bold'>{translate('manageRequests')}</p>
                    <Button onClick={executeScroll}>{translate('orderDemo')}</Button>
                </div>
            </section>

            <section className='decision'>
                <div className='container'>
                    <p className='decision__axilink-text'>{translate('axiLinkDesc')}.</p>
                    <div className='decision__functionality'>
                        <ul className='dashed'>
                            <li>{translate('fn_collecting')}</li>
                            <li>{translate('fn_support')}</li>
                            <li>{translate('fn_transformation')}</li>
                            <li>{translate('fn_depersonalization')}</li>
                            <li>{translate('fn_cashing')}</li>
                            <li>{translate('fn_parameterization')}</li>
                            <li>{translate('fn_storageAndLogging')}</li>
                        </ul>
                    </div>

                    <p className='top'><b>{translate('noChaos')}</b>{translate('withTuning')}</p>
                    <div className='cover_outside'>
                        <div className='info_block'>
                            <p><b>{translate('single')}</b>{translate('reqFormat')}</p>
                        </div>
                        <div className='info_block'>
                            <p><b>{translate('readyIntegration')}</b>{translate('analyticalSystems')}</p>
                        </div>
                        <div className='info_block'>
                            <p><b>{translate('support')}</b>{translate('majorProtocols')}</p>
                        </div>
                        <div className='info_block'>
                            <p><b>{translate('viewPossibility')}</b>{translate('crmData')}</p>
                        </div>
                    </div>
                    <h2>{translate('whoIs')}<b>{translate('solutionFor')}</b></h2>
                    <p className='bott'>{translate('clients')}</p>
                </div>
            </section>

            <section className='our_vision'>
                <div className='container'>
                    <h3>{translate('ourSolution')}</h3>
                    <div className='info_text'>
                        <p className='title'>{translate('it')}</p>
                        <p>{translate('businessFocus')}</p>
                    </div>
                    <div className='info_text'>
                        <p className='title'>{translate('riskTechnologies')}</p>
                        <p>{translate('automatedChecks')}</p>
                    </div>
                    <div className='info_text'>
                        <p className='title'>{translate('business')}</p>
                        <p>{translate('fastStartGuarantee')}</p>
                    </div>
                </div>
            </section>

            <section className='why_us'>
                <div className='container'>
                    <h2><b>{translate('why')}</b>{translate('axiLink')}{translate('questionMark')}</h2>
                    <div className='cover_outside'>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={scalability} alt='ico_7'/>
                            </div>
                            <div className='info_block'>
                                <p>{translate('scalability')}</p>
                                <span>{translate('requestProcessing')}</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={interface_1} alt='ico_10'/>
                            </div>
                            <div className='info_block'>
                                <p>{translate('versatility')}</p>
                                <span>{translate('allInfoSystems')}</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={rocket} alt='ico_8'/>
                            </div>
                            <div className='info_block'>
                                <p>{translate('speed')}</p>
                                <span>{translate('integrationSpeed')}</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={time} alt='ico_11'/>
                            </div>
                            <div className='info_block'>
                                <p>{translate('saving')}</p>
                                <span>{translate('costReduction')}</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={payment} alt='ico_9'/>
                            </div>
                            <div className='info_block'>
                                <p>{translate('security')}</p>
                                <span>{translate('integrationSecurity')}</span>
                            </div>
                        </div>
                        <div className='cover_inside'>
                            <div className='img_block'>
                                <img src={analytics} alt='ico_12'/>
                            </div>
                            <div className='info_block'>
                                <p>{translate('fineTuning')}</p>
                                <span>{translate('requestManagement')}</span>
                            </div>
                        </div>
                    </div>
                    <p className='end_title'>{translate('dateStore')}</p>
                    <Button onClick={executeScroll}>{translate('orderDemo')}</Button>
                </div>
            </section>

            <section className='list'>
                <div className='container'>
                    <h2>{translate('externalDataProvidersList')}</h2>
                    <Accordion/>
                </div>
            </section>
            <section>
                <div className='container'>
                    <div className='doc-title'>{translate('documentation')}</div>
                    <div className='doc-list'>
                        <div className='doc-link'>
                            <a href={usersGuide} target='_blank' rel='noreferrer'>{translate('usersGuide')}</a>
                        </div>
                        <div className='doc-link'>
                            <a href={installationInstructions} target='_blank' rel='noreferrer'>{translate('installationInstructions')}</a>
                        </div>
                        <div className='doc-link'>
                            <a href={softwareAdditionalInteraction} target='_blank' rel='noreferrer'>{translate('softwareAdditionalInteraction')}</a>
                        </div>
                        <div className='doc-link'>
                            <a href={lifeCycleProcessesDescription} target='_blank' rel='noreferrer'>{translate('lifeCycleProcessesDescription')}</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className='demo'>
                <div className='container _flex'>
                    <div className='demo-prices'>
                        <div className='demo-prices__order'>
                            <span>от 30 000 &#8381;/{translate('month')}</span>
                        </div>
                        <div className='demo-prices__desc'>
                            <span>{translate('connectionCost')}</span>
                        </div>
                    </div>
                    <div className='demo-prices'>
                        <div className='demo-prices__order'>
                            <span>от 5 000 &#8381;/{translate('month')}</span>
                        </div>
                        <div className='demo-prices__desc'>
                            <span>{translate('service')}</span>
                        </div>
                    </div>
                    <Button onClick={executeScroll}>{translate('getDemo')}</Button>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='support-title'>{translate('supportService')}</div>
                    <div className='support-list'>
                        <div className='support__link'>
                            <span className='support__link__label'>{translate('supportPhoneNumber')}:</span>
                            <a className='support__link__content' href='tel:+74953748864'>+7(495)3748864</a>
                        </div>
                        <div className='support-link'>
                            <span className='support__link__label'>Email:</span>
                            <a className='support__link__content' href='mailto:support@axiomatika.ru'>support@axiomatika.ru</a>
                        </div>
                    </div>
                </div>
            </section>
            <div ref={myRef}>
                <Form/>
            </div>
            <Footer/>
        </div>
    );
};

export default FrontPage;
