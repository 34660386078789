import { LOCALES } from '../locales';

export default {
    [LOCALES.RUSSIAN]: {
        'headerTitle': 'Одна интеграция - все источники!',
        'headerSubtitle': 'Запускайте проекты и комфортно взаимодействуйте с большим числом поставщиков данных',
        'how': 'Как узнать всë о клиенте?',
        'strongProductSides': '1. Изучить сильные стороны нашего продукта',
        'formatsAgree': '2. Согласовать форматы и подписать договор',
        'implementSystem': '3. Внедрить систему',
        'connectSources': '4. Подключить источники',
        'startProject':'5. Запустить проект',
        'arrangeSupport':'6. Организовать поддержку',
        'firstStep':'От вас – только первый шаг.',
        'takingObligations':' Мы возьмем на себя все обязательства по сопровождению',
        'whatIs':'Что такое ',
        'axiLink':'AxiLink',
        'axiLinkDescMain': `AxiLink – программный продукт по автоматизированному сбору, обработке и дедупликации данных
         о потенциальном клиенте финансовой̆ организации из различных источников (БКИ, сервисы противодействия мошенничеству,
          открытые базы данных и т.д.).`,
        'axiLinkDesc':`AxiLink – не просто запатентованное решение, которое обеспечивает единое «окно» при
            взаимодействии с
            поставщиками данных. Наш продукт оптимизирует ваши затраты на IT-ресурсы и позволяет полностью
            автоматизировать процессы андеррайтинга и верификации при кредитовании и страховании.`,
        'fn_collecting': 'Сбор данных в синхронном/асинхронном режиме',
        'fn_support': 'Поддержка защищенных каналов передачи данных (ГОСТ, TLS и тд)',
        'fn_transformation': 'Преобразование данных в единый межсистемный формат',
        'fn_depersonalization': 'Деперсонализация данных',
        'fn_cashing': 'Кэширование запросов',
        'fn_parameterization': 'Параметризация настройки запросов количество попыток, время ожидания, определение статуса источника терминальный нетерминальный',
        'fn_storageAndLogging': 'Хранение и логирование данных',
        'manageRequests':'Управлять запросами и данными может каждый, но делать это быстро и качественно - исключительно AxiLink.',
        'noChaos':'Никакого хаоса. Только порядок',
        'withTuning':' с тонкой настройкой под ваш бизнес',
        'single':'Единый',
        'reqFormat':' формат запросов',
        'readyIntegration':'Готовые интеграции',
        'analyticalSystems':' с промышленными аналитическими системами (FICO, Experian SAS, Drools)',
        'support':'Поддержка',
        'majorProtocols':' основных протоколов и режимов взаимодействия',
        'viewPossibility':'Возможность визуализации',
        'crmData':' данных в вашей CRM',
        'whoIs':'Для кого ',
        'solutionFor':'это решение?',
        'clients':`Всем бизнес-проектам, которые нуждаются в доступном решении для интеграции с БКИ,
            госучреждениями, сотовыми операторами и другими поставщиками данных. Нашим продуктом пользуются
            клиенты в 13 странах мира, от банков и МФО до страховых компаний и онлайн-сервисов.`,
        'ourSolution':'Наше решение — ваше всестороннее развитие',
        'it':'IT.',
        'businessFocus':`Высвобождение ресурсов и фокус на развитие бизнеса. Если вы работаете с большим количеством
            продуктов и поставщиков данных, то вам нужно простое и эффективное решение по интеграции,
            которое закроет все вопросы. Наша команда настроит взаимодействие с информационными системами,
            проведёт тестирование и выведет проект в боевой режим.`,
        'riskTechnologies':'Риск-технологии.',
        'automatedChecks':`Постоянно боретесь с фродом и работаете с большим количеством данных, мечтая
            автоматизировать процессы сбора и обработки? Мы знаем, как оперативно решить поставленные задачи. AxiLink
            осуществляет все автоматизированные проверки в рамках обработки кредитных заявок и помогает сформировать исчерпывающий портрет заемщика.`,
        'business':'Бизнес.',
        'fastStartGuarantee':`Гарантия быстрого запуска новых направлений по онлайн-кредитованию на рынок и снижения
            расходов. Пользователи AxiLink легко определяются с набором поставщиков данных и могут за 1 месяц
            запускать нужный функционал.`,
        'why':'Почему ',
        'questionMark':'?',
        'scalability':'Масштабируемость',
        'requestProcessing':'Обработка более 10 миллионов запросов ежемесячно, что обеспечивает потребности даже крупных банков.',
        'versatility':'Универсальность',
        'allInfoSystems':'Взаимодействие с любыми информационными системами и адаптивность к любым форматам данных. Интеграция с источниками данных по физическим и юридическим лицам.',
        'speed':'Скорость',
        'integrationSpeed':'В среднем срок интеграции с внешними источниками через наш сервис происходит за одну неделю.<',
        'saving':'Экономия',
        'costReduction':'На 80% снижаются затраты на поддержку интеграций, на 30% сокращается расход средств за счет кеширования, на 20% ускоряется обработка запросов.',
        'security':'Безопасность',
        'integrationSecurity':'Все интеграции осуществляются от лица Заказчика, мы не имеем доступа к персональным или чувствительным данным.',
        'fineTuning':'Тонкая настройка',
        'requestManagement':'Управление параллельными и последовательными запросами, таймаутами и числом попыток.',
        'dateStore':'В результате работы сервиса, вы получите полноценное хранилище данных и готовые инструменты для работы с ним.',
        'externalDataProvidersList':'Список поставщиков внешних данных с готовой интеграцией',
        'month':'мес',
        'connectionCost':'Стоимость подключения',
        'service':'Обслуживание',
        'orderDemo': 'заказать демо',
        'getDemo': 'получить демо',
        'send': 'Отправить',
        'product': 'продукт',
        'companyName':'Аксиоматика',
        'tariffs':'Тарифы',
        'company':'Компания',
        'jobs':'Вакансии',
        'contacts':'Контакты',
        'integrations':'Интеграции',
        'developers':'Разработчикам',
        'axiomatika':'axiomatika',
        'about':'О ',
        'thanksTo': ' ',
        'knowAboutClient': 'Знать всё о клиентах просто',
        'automateProcesses': ' благодаря AxiLink. Автоматизируйте процессы оценки и взаимодействия – развивайте свой бизнес в ускоренном темпе!',
        'fullName': 'Имя',
        'email': 'Email',
        'phoneNumber': 'Номер телефона',
        'message': 'Сообщение',
        'documentation': 'Документация',
        'usersGuide': 'Руководство пользователя',
        'installationInstructions': 'Инструкция по установке',
        'softwareAdditionalInteraction': 'Дополнительное взаимодействие с ПО',
        'lifeCycleProcessesDescription': 'Описание процессов жизненного цикла',
        'supportService': 'Служба технической поддержки',
        'supportPhoneNumber': 'Телефон'
    }
};
